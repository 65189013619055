import React from "react";
import Terminal from "./Terminal/Terminal"; // Import the Terminal component
import "./Projects.css";

const Projects = () => {
  return (
    <div className="projects">
      <h1>Projects</h1>
      <p>Here you’ll find interactive netrunner tools and code examples.</p>
      <div className="terminal-container">
        <Terminal />
      </div>
    </div>
  );
};

export default Projects;
