import React from 'react';
import './Welcome.css'; // Import Welcome.css for styling

function Welcome() {
  return (
    <div className="App">
      <div className="animated-background"></div> {/* Animated Background */}
      <header className="App-header">
        <img src="/android-chrome-512x512.png" alt="Meoware Logo" className="main-logo" />
        <div className="intro-content">
          <h1>Welcome to Meoware.net</h1>
          <p>Your hub for everything related to hacking, netrunning, and cyberpunk culture.</p>
          <a
            className="intro-link"
            href="#"
          >
            Learn More
          </a>
        </div>
      </header>
    </div>
  );
}

export default Welcome;
