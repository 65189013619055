import React, { useState, useEffect, useRef } from "react";
import fileSystem from "./files";
import "./Terminal.css";

const Terminal = () => {
  const [output, setOutput] = useState([]);
  const [command, setCommand] = useState("");
  const [isBooting, setIsBooting] = useState(true);
  const [bootSequence, setBootSequence] = useState([
    "Initializing EdgeRunnerOS v0.1...",
    "Loading kernel modules...",
    "0xA2F4: Cyberlib kernel initialized.",
    "0xE7C3: Networking module loaded.",
    "Connecting to Meoware.net...",
    "Authenticating user...",
    "0x5B6D: Core protocols activated.",
    "All systems nominal. Welcome, Edgerunner."
  ]);
  const [currentBootIndex, setCurrentBootIndex] = useState(0);
  const terminalOutputRef = useRef(null); // Reference for auto-scrolling

  useEffect(() => {
    // Simulate the bootup sequence
    if (isBooting && currentBootIndex < bootSequence.length) {
      const timeout = setTimeout(() => {
        setOutput((prev) => [...prev, bootSequence[currentBootIndex]]);
        setCurrentBootIndex((prev) => prev + 1);
      }, 800); // Typing speed for boot sequence
      return () => clearTimeout(timeout);
    } else if (isBooting && currentBootIndex === bootSequence.length) {
      setTimeout(() => {
        setOutput((prev) => [
          ...prev,
          "Welcome to the Blackwire, Edgerunner. Type 'help' to view available commands."
        ]);
        setIsBooting(false);
      }, 800);
    }
  }, [currentBootIndex, isBooting, bootSequence]);

  useEffect(() => {
    // Auto-scroll to the bottom whenever output changes
    if (terminalOutputRef.current) {
      terminalOutputRef.current.scrollTop = terminalOutputRef.current.scrollHeight;
    }
  }, [output]);

  const processCommand = (cmd) => {
    const args = cmd.trim().split(" ");
    const commandName = args[0];
    const argument = args[1];

    switch (commandName) {
      case "help":
        setOutput((prev) => [
          ...prev,
          "Available commands: ls, cat <filename>, help"
        ]);
        break;

      case "ls":
        setOutput((prev) => [
          ...prev,
          "Files in root directory:",
          ...fileSystem.root.files,
          "Directories:",
          ...Object.keys(fileSystem.root.subdirs)
        ]);
        break;

      case "cat":
        if (argument && fileSystem.root.files.includes(argument)) {
          setOutput((prev) => [
            ...prev,
            `Content of ${argument}:`,
            `This is a placeholder content for ${argument}.`
          ]);
        } else {
          setOutput((prev) => [
            ...prev,
            "File not found or invalid command. Try 'ls' to see available files."
          ]);
        }
        break;

      default:
        setOutput((prev) => [
          ...prev,
          "Invalid command. Type 'help' for a list of commands."
        ]);
    }
  };

  const handleInput = (e) => {
    e.preventDefault();
    setOutput((prev) => [...prev, `$ ${command}`]);
    processCommand(command);
    setCommand("");
  };

  return (
    <div className="terminal">
      <div className="terminal-output" ref={terminalOutputRef}>
        {output.map((line, index) => (
          <p key={index} className="terminal-line">{line}</p>
        ))}
        {isBooting && <p className="terminal-cursor">_</p>}
      </div>
      {!isBooting && (
        <form onSubmit={handleInput}>
          <input
            type="text"
            className="terminal-input"
            value={command}
            onChange={(e) => setCommand(e.target.value)}
            autoFocus
            placeholder="Type a command..."
          />
        </form>
      )}
    </div>
  );
};

export default Terminal;
