// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Share+Tech+Mono&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Import ShareTech Mono from Google Fonts */

body {
  font-family: 'Share Tech Mono', monospace; /* Retro font */
  background-color: #121212; /* Dark background */
  color: #ddd; /* Light text */
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

.App {
  text-align: center;
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA,4CAA4C;;AAG5C;EACE,yCAAyC,EAAE,eAAe;EAC1D,yBAAyB,EAAE,oBAAoB;EAC/C,WAAW,EAAE,eAAe;EAC5B,SAAS;EACT,UAAU;EACV,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;AACpB","sourcesContent":["/* Import ShareTech Mono from Google Fonts */\n@import url('https://fonts.googleapis.com/css2?family=Share+Tech+Mono&display=swap');\n\nbody {\n  font-family: 'Share Tech Mono', monospace; /* Retro font */\n  background-color: #121212; /* Dark background */\n  color: #ddd; /* Light text */\n  margin: 0;\n  padding: 0;\n  overflow-x: hidden;\n}\n\n.App {\n  text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
