export const blurb = "Peace of Mind Spine connects you with top, board-certified spinal surgeons who provide unbiased second opinions on spinal surgery recommendations. Our specialists offer professional and experienced guidance without any solicitation for surgery, ensuring you receive a fair, affordable, and impartial evaluation to help you make the best decision for your spinal health and quality of life."

export const story = "Peace of Mind Spine endeavors to offer customers an efficient and effective online portal for independent evaluations of their spinal conditions. Our goal is to educate the customer on their management options for their spinal conditions. The primary focus of POMS is to allow those who have been offered surgical or other invasive intervention the opportunity to receive a non-biased opinion regarding the treatments offered and the likelihood of their success."

export const bioA = "Dr. Cary R. Templin, the founder of Peace of Mind Spine, is a fellowship trained, practicing spinal surgeon and is Board Certified by the American Board of Orthopaedic Surgeons. His philosophy is to do the most limited surgery possible to maximize quality of life for his own patients. As a practicing spine surgeon for nearly 20 years, Dr. Templin is dedicated to making sure that each Peace of Mind Spine customer receives advice on the most appropriate treatment, including surgery, when necessary."

export const bioB = "Most spinal surgeons have the patient's best interest in mind when considering surgical intervention. There are, however, those that have expanded operative indications and procedures.  The bottom line is spine surgeons get paid money to perform surgery on patients. Financial reward is NOT dependent on patient outcome. Our ideal at POMS is to provide you an independent evaluation that is not financially tied to your surgical treatment. The ultimate goal is to educate you regarding your spine. Some patients are offered surgery with an extremely low likelihood that they will improve. Some are offered surgery that is too aggressive. Others have a significant likelihood of improvement with surgery. We are here to educate you regarding your options to maximize your success with surgery and to avoid it if you have a low likelihood of success. With our input, we can give you confidence knowing that the intervention you will potentially undergo has a strong chance of improving your overall health, livelihood, and quality of life. We take into account scientific literature and our personal experience in treating our own patients."

export const bioC = "Having undergone surgery on his spine, Dr. Templin knows first hand the risk that comes with trusting the process. As a devoted family man, he works diligently to empower every customer to feel understood and centered in their treatment plan. With the help of his experienced team, his mission is unwavering to improve each customer’s quality of life through exceptional care and personalized treatment. Dr. Templin brings a wealth of knowledge and skill in practice, and now seeks to expand to see that every customer feels that they have a choice, and a chance to be their best again."

