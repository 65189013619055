// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.projects {
  padding: 20px;
  text-align: center;
  color: #00ffcc;
  font-family: "Share Tech Mono", monospace;
  background: #000;
  min-height: 100vh;
}

.projects h1 {
  font-size: 2.5rem;
  margin-bottom: 10px;
  text-shadow: 0 0 10px #00ffcc;
}

.projects p {
  font-size: 1.2rem;
  margin-bottom: 20px;
  color: #007766;
}

.terminal-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 10px;
  box-shadow: 0 0 15px #00ffcc;
  border-radius: 10px;
}
`, "",{"version":3,"sources":["webpack://./src/components/Projects.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,kBAAkB;EAClB,cAAc;EACd,yCAAyC;EACzC,gBAAgB;EAChB,iBAAiB;AACnB;;AAEA;EACE,iBAAiB;EACjB,mBAAmB;EACnB,6BAA6B;AAC/B;;AAEA;EACE,iBAAiB;EACjB,mBAAmB;EACnB,cAAc;AAChB;;AAEA;EACE,gBAAgB;EAChB,cAAc;EACd,aAAa;EACb,4BAA4B;EAC5B,mBAAmB;AACrB","sourcesContent":[".projects {\n  padding: 20px;\n  text-align: center;\n  color: #00ffcc;\n  font-family: \"Share Tech Mono\", monospace;\n  background: #000;\n  min-height: 100vh;\n}\n\n.projects h1 {\n  font-size: 2.5rem;\n  margin-bottom: 10px;\n  text-shadow: 0 0 10px #00ffcc;\n}\n\n.projects p {\n  font-size: 1.2rem;\n  margin-bottom: 20px;\n  color: #007766;\n}\n\n.terminal-container {\n  max-width: 800px;\n  margin: 0 auto;\n  padding: 10px;\n  box-shadow: 0 0 15px #00ffcc;\n  border-radius: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
