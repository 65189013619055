const fileSystem = {
  root: {
    files: ["readme.txt", "intro.md"],
    subdirs: {
      scripts: {
        files: ["lantern.js", "blackwire.py", "bartmoss.sh"],
      },
    },
  },
};

export default fileSystem;
