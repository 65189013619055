import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navbar from './components/Navbar'; // Import Navbar
import Welcome from './components/Welcome'; // Import Welcome Page
import Projects from './components/Projects'; // Import Projects Page
import './App.css';

function App() {
  return (
    <Router>
      <div className="App">
        <Navbar /> {/* Navbar */}
        <Routes>
          <Route path="/" element={<Welcome />} /> {/* Welcome Page */}
          <Route path="/projects" element={<Projects />} /> {/* Projects Page */}
        </Routes>
      </div>
    </Router>
  );
}

export default App;
